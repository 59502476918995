'use client';

import {
  FloatingFocusManager,
  FloatingOverlay,
  FloatingPortal,
  autoUpdate,
  offset,
  size,
  useDismiss,
  useFloating,
  useInteractions,
  useTransitionStatus,
} from '@floating-ui/react';
import { FC, ReactNode } from 'react';

import styles from './PopperMenu.module.scss';

interface Props {
  anchor: Element | null;
  isOpen: boolean;
  setIsOpen: (isOpen: boolean) => void;
  children: ReactNode;
}

export const PopperMenu: FC<Props> = ({ anchor, isOpen, setIsOpen, children }) => {
  const { refs, floatingStyles, context } = useFloating({
    open: isOpen,
    onOpenChange: setIsOpen,
    placement: 'bottom-end',
    transform: false,
    middleware: [
      offset(8),
      size({
        apply({ availableWidth, availableHeight, elements }) {
          Object.assign(elements.floating.style, {
            // Offset from screen's edge
            maxHeight: `${availableHeight - 16}px`,
            maxWidth: `${availableWidth - 16}px`,
          });
        },
      }),
    ],
    whileElementsMounted: autoUpdate,
    elements: {
      reference: anchor,
    },
  });
  const dismiss = useDismiss(context);
  const { isMounted, status } = useTransitionStatus(context, { duration: 200 });

  const { getFloatingProps } = useInteractions([dismiss]);

  return (
    <>
      {isMounted && (
        <FloatingPortal>
          <FloatingOverlay lockScroll className={styles.root}>
            <FloatingFocusManager context={context} modal={false}>
              <div
                className={styles.content}
                ref={refs.setFloating}
                style={floatingStyles}
                {...getFloatingProps()}
                data-status={status}
              >
                {children}
              </div>
            </FloatingFocusManager>
          </FloatingOverlay>
        </FloatingPortal>
      )}
    </>
  );
};
